<template>
<loader v-bind="{ loading }">

    <columns>
        <column>
            <h1 class="title">{{ checklist.checklist.name }}</h1>
            <h4 class="subtitle is-6 has-text-grey">
                Performed by {{ checklist.user.full_name }}. Active {{ checklist.updated_at | asCalendar }}
            </h4>
        </column>
        <column class="is-narrow">
            <div class="buttons has-addons is-rounded">
                <download-button
                    :path="`/checklists/${checklist.uuid}/download-pdf`"
                    :file-name="`#${checklist.id}-${checklist.checklist.name}.pdf`"
                    left-icon="download" 
                    class="is-rounded is-small">
                    Download Report
                </download-button>
                <router-link v-if="!$root.isPassiveUser()" class="button is-rounded is-small" :to="{
                    name: 'checklist-template-manager',
                    params: {
                        checklistTemplate: checklist.checklist.uuid
                    }
                }">Checklist Template</router-link>
                <action-button
                    v-if="!$root.isPassiveUser()"
                    @click="deleteChecklist"
                    :working="deleting"
                    left-icon="trash" 
                    class="is-rounded is-small is-danger">
                    Delete
                </action-button>
            </div>
        </column>
    </columns>

    <router-view />
</loader>    
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    
    data: () => ({
        loading: true,
        deleting: false
    }),

    beforeCreate() {
        this.$store.dispatch('inspection/load', this.$route.params.checklist).finally(() => this.loading = false)
    },

    beforeDestroy() {
        this.$store.commit('inspection/clear')
    },

    methods: {
        async deleteChecklist() {
            if(await this.$confirm({
                title: 'Delete Checklist',
                message: 'Are you sure you want to delete this checklist?'
            })) {
                this.deleting = true
                this.$store.dispatch('inspection/deleteChecklist', this.checklist.uuid).then(() => {
                    this.$router.back()
                    this.deleting = false
                    this.$toast.success('Successfully deleted.')
                }).catch(() => this.deleting = false)
            }
        }
    },

    computed: {
        ...mapGetters('inspection', [
            'checklist'
        ])
    }

}
</script>